import { useState, ChangeEvent, KeyboardEvent } from 'react';
import { apiClient } from '../../../../../../api/apiClient';
import styles from './styles.module.css';

export const ModelStep = ({ goToNextStep }: { goToNextStep: (data: any) => void }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [value, setValue] = useState(''/* 'Test-Model-1' */);
  const [errorMessage, setErrorMessage] = useState('');

  const onCheckNumber = async () => {
    try {
      setIsSubmitting(true);
      const res = await apiClient.get(`/product/getByName/${value}`);
      const resSupportedPartModels = await apiClient.get(`/component/getActivation/${res?.data?.id}`);
      goToNextStep({
        modelNumber: {
          id: res?.data?.id,
          name: res?.data?.name ?? value,
          img: res?.data?.images?.[0]?.url,
          supportedPartModels: resSupportedPartModels?.data ?? [],
        },
      });
    } catch (e) {
      setValue('');
      const error = (e as any)?.response?.data?.error;
      const errorMsg = typeof error === 'string' ? error : error?.message;
      setErrorMessage(errorMsg);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setErrorMessage('');
  };

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onCheckNumber();
    }
  };

  return (
    <div>
      <input
        className={`${styles.input} ${!!errorMessage && styles.errorInput}`}
        onKeyDown={onKeyDown}
        value={value}
        disabled={isSubmitting}
        onChange={onChange}
        placeholder='Enter model number'
        key={errorMessage}
        autoFocus
      />
      <div className={styles.error}>
        {errorMessage}
      </div>
    </div>
  );
};
