import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MAFooter } from '../../components/MAFooter';
import { apiClient } from '../../../../api/apiClient';
import styles from './styles.module.css';

export const ContinueExistingPage = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [value, setValue] = useState(''/*'12121280011111'*/);
  const [errorMessage, setErrorMessage] = useState('');

  const onCheckNumber = async () => {
    try {
      setIsSubmitting(true);
      const res = await apiClient.get(`/asset/findActive/${value}`);
      const modelId = res?.data?.product?.id;
      const resSupportedPartModels = await apiClient.get(`/component/getActivation/${modelId}`);
      const modelName = res?.data?.product?.name ?? value ?? '';
      const customerData = res?.data?.customer;
      const supportedPartModels = resSupportedPartModels?.data ?? [];
      const initialStepsData: Record<string, any> = {
        modelNumber: { id: modelId, name: modelName, supportedPartModels },
        serialNumber: res?.data?.serialNumber,
      };
      const boards = (res?.data?.component_assets ?? []).map((componentAsset: any) => ({
        id: componentAsset.related_component?.id,
        name: componentAsset.related_component?.name,
        serialNumber: componentAsset.partID,
        type: 'Board',
      }));
      const labels = (res?.data?.q_rcodes ?? []).map((labelData: any, index: number) => {
        const pmLabel = supportedPartModels.filter((pm: any) => pm.type === 'Label')[index];
        if (!pmLabel) {
          return undefined;
        }

        return {
          id: pmLabel?.id ?? labelData.qrID,
          name: pmLabel?.name ?? labelData.qrID,
          serialNumber: labelData.qrID,
          type: 'Label',
        };
      }).filter((label: any) => label);
      const componentItems = [...boards, ...labels];
      if (customerData) {
        initialStepsData.customer = { id: customerData.id, name: customerData.Name };
      }
      const shouldRedirectToReviewStep = componentItems.length;
      if (shouldRedirectToReviewStep) {
        initialStepsData.components = { items: componentItems, name: `${(componentItems.length ?? 0)} items` };
      }
      navigate(
        `/manufacturing-activation/activate?step=${shouldRedirectToReviewStep ? 'review' : 'components'}`,
        { state: { initialStepsData } },
      );
    } catch (e) {
      setValue('');
      setErrorMessage((e as any)?.response?.data.error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setErrorMessage('');
  };

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onCheckNumber();
    }
  };

  const goToPrevStep = () => {
    navigate(-1);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Scan serial number or QR code</div>
      <input
        className={`${styles.input} ${!!errorMessage && styles.errorInput}`}
        onKeyDown={onKeyDown}
        value={value}
        disabled={isSubmitting}
        onChange={onChange}
        placeholder='Enter serial number or code'
        key={errorMessage}
        autoFocus
      />
      <div className={styles.error}>
        {errorMessage}
      </div>
      <MAFooter onGoBack={goToPrevStep} />
    </div>
  );
};
